<template>
  <div class="outer-wrapper">
    <div class="inner-wrapper">
      <div class="data-wrapper">
        <div class="base-data-box">
          <div class="select-box">
            <select v-model="choosed.year" style="width: 250px" class="select" @change="valueChange">
              <option :value="y.value" v-for="y in option.year" :key="y.value">
                {{ y.text }}
              </option>
            </select>
          </div>
          <div class="tab-bar">
            <div class="scroll">
              <ul
                class="line-list"
                style="
                  transition-timing-function: cubic-bezier(0.1, 0.57, 0.1, 1);
                  transition-duration: 500ms;
                  transform: translate(0px, 0px) translateZ(0px);
                "
              >
                <li
                  class="tab-term"
                  :class="{ 'ch-line': line.value === choosed.line }"
                  v-for="line in option.line"
                  :style="'color:' + line.color"
                  :key="line.value + 'line'"
                  @click="chooseLine(line)"
                >
                  <div class="cir">
                    <div class="ico">
                      <img :src="curIcon" alt />
                    </div>
                  </div>
                  <p class="p">{{ line.text }}</p>
                  <i class="arr"></i>
                </li>
              </ul>
            </div>
            <span class="prev" @click="prevTo"></span>
            <span class="next" @click="nextTo"></span>
          </div>
          <div class="base-data" v-if="totalData.length > 0">
            <div class="base-data-element">
              <p class="ele-num">{{ animateShowData.runDays.toFixed(0) }}</p>
              <p class="ele-title">实际运营天数</p>
            </div>
            <div class="base-data-element">
              <p class="ele-num">{{ animateShowData.runMil.toFixed(0) }}</p>
              <p class="ele-title">线路运营里程（万公里）</p>
            </div>
            <div class="base-data-element">
              <p class="ele-num">
                {{ animateShowData.actualAmount.toFixed(0) }}
              </p>
              <p class="ele-title">线路实际开行列次</p>
            </div>
            <div class="base-data-element">
              <p class="ele-num">{{ animateShowData.planRatio.toFixed(0) }}%</p>
              <p class="ele-title">列车运行图兑现率</p>
            </div>
            <div class="base-data-element">
              <p class="ele-num">{{ animateShowData.ontimeRatio }}%</p>
              <p class="ele-title">线路列车准点率</p>
            </div>
            <div class="base-data-element">
              <p class="ele-num">
                {{ animateShowData.yearPassengerFlow.toFixed(2) }}
              </p>
              <p class="ele-title">线路客运量( 万乘次)</p>
            </div>
            <div class="base-data-element">
              <p class="ele-num">
                {{ animateShowData.maxHourFlow }}
              </p>
              <p class="ele-title">线路高峰小时高断面客流量(万人次/小时)</p>
            </div>
            <div class="base-data-element">
              <p class="ele-num">
                {{ animateShowData.dayAverageTransfer }}
              </p>
              <p class="ele-title">线路日均换乘客流量(万人次/日)</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {TweenMax} from 'gsap';
// import "../../../public/js/TweenMax.min.js";
let userToken = localStorage.getItem("current_user_token");
/*
const lineData = [
    {line:'2号线',year:'2017',passengerFlow:23469.48,runDays:365,runMil:701.41,maxInterval:160,normalInterval:300,planAmount:181577,actualAmount:181626,axtraAmount:49,averageDayAmount:498,planRatio:100,ontimeAmount:181611,ontimeRatio:99.99,
    delayAmount:{
        total:15,cheliang:0,gongwu:0,gongdian:0,xinhao:9,pingbimen:1,diaodu:0,chengwu:1,zhanwu:0,chengke:1,otherEquipment:0,other:3
    },
    delayEvent:{m5:5,m15:0,m30:0},reliability:{m5:841.7,m15:0,m30:0},speed:{up:35.14,down:35.05},tripTime:{up:71.17,down:71.33},totalTime:150,cleanPassenger:4,cleanPassengerFrequency:1052.12,rescueAmount:0},

  {line:'3号线',year:'2017',passengerFlow:13444.52,runDays:365,runMil:256.46,maxInterval:210,normalInterval:320,planAmount:131510,actualAmount:131534,axtraAmount:24,averageDayAmount:360,planRatio:100,ontimeAmount:131526,ontimeRatio:99.99,
    delayAmount:{
        total:8,cheliang:0,gongwu:2,gongdian:0,xinhao:2,pingbimen:0,diaodu:0,chengwu:0,zhanwu:0,chengke:4,otherEquipment:0,other:0
    },
    delayEvent:{m5:4,m15:0,m30:0},reliability:{m5:384.69,m15:0,m30:0},speed:{up:34.40,down:34.20},tripTime:{up:34.05,down:34.25},totalTime:75.72,cleanPassenger:0,cleanPassengerFrequency:0,rescueAmount:0},

  {line:'4号线',year:'2017',passengerFlow:15983.36,runDays:365,runMil:488.78,maxInterval:190,normalInterval:320,planAmount:153768,actualAmount:153794,axtraAmount:26,averageDayAmount:421,planRatio:100,ontimeAmount:153788,ontimeRatio:100,
    delayAmount:{
        total:6,cheliang:3,gongwu:1,gongdian:0,xinhao:0,pingbimen:0,diaodu:0,chengwu:0,zhanwu:0,chengke:1,otherEquipment:0,other:1
    },
    delayEvent:{m5:2,m15:0,m30:0},reliability:{m5:1466.34,m15:0,m30:0},speed:{up:36.87,down:36.71},tripTime:{up:68.85,down:69.15},totalTime:145,cleanPassenger:1,cleanPassengerFrequency:2932.68,rescueAmount:0},

  {line:'2号线',year:'2018',passengerFlow:26495.87,runDays:365,runMil:687.99,maxInterval:160,normalInterval:300,planAmount:176261,actualAmount:176325,axtraAmount:64,averageDayAmount:483,planRatio:100,ontimeAmount:176298,ontimeRatio:99.98,
    delayAmount:{
        total:27,cheliang:5,gongwu:1,gongdian:0,xinhao:16,pingbimen:3,diaodu:0,chengwu:1,zhanwu:0,chengke:0,otherEquipment:0,other:2
    },
    delayEvent:{m5:6,m15:1,m30:0},reliability:{m5:687.99,m15:4127.92,m30:0},speed:{up:35.10,down:35.10},tripTime:{up:71.25,down:71.25},totalTime:150,cleanPassenger:2,cleanPassengerFrequency:2063.96,rescueAmount:0},

  {line:'3号线',year:'2018',passengerFlow:16425.22,runDays:365,runMil:281.21,maxInterval:190,normalInterval:320,planAmount:140517,actualAmount:140550,axtraAmount:33,averageDayAmount:385,planRatio:100,ontimeAmount:140536,ontimeRatio:99.99,
    delayAmount:{
        total:14,cheliang:0,gongwu:0,gongdian:0,xinhao:10,pingbimen:2,diaodu:0,chengwu:0,zhanwu:0,chengke:0,otherEquipment:0,other:2
    },
    delayEvent:{m5:5,m15:0,m30:0},reliability:{m5:337.46,m15:0,m30:0},speed:{up:36.41,down:36.41},tripTime:{up:81.79,down:81.79},totalTime:170.92,cleanPassenger:0,cleanPassengerFrequency:0,rescueAmount:0},

  {line:'4号线',year:'2018',passengerFlow:20437.57,runDays:365,runMil:605.57,maxInterval:190,normalInterval:320,planAmount:156158,actualAmount:156281,axtraAmount:123,averageDayAmount:428,planRatio:100,ontimeAmount:156278,ontimeRatio:100,
    delayAmount:{
        total:3,cheliang:2,gongwu:0,gongdian:0,xinhao:1,pingbimen:0,diaodu:0,chengwu:0,zhanwu:0,chengke:0,otherEquipment:0,other:0
    },
    delayEvent:{m5:1,m15:0,m30:0},reliability:{m5:3633.44,m15:0,m30:0},speed:{up:36.79,down:36.79},tripTime:{up:69,down:69},totalTime:145,cleanPassenger:0,cleanPassengerFrequency:0,rescueAmount:0},
    
];*/

function initialDataFac() {
  return {
    runDays: 0,
    yearPassengerFlow: 0,
    runMil: 0,
    maxInterval: 0,
    normalInterval: 0,
    planAmount: 0,
    actualAmount: 0,
    axtraAmount: 0,
    averageDayAmount: 0,
    planRatio: 0,
    ontimeAmount: 0,
    ontimeRatio: 0,
    delayAmount: {
      total: 0,
      cheliang: 0,
      gongwu: 0,
      gongdian: 0,
      xinhao: 0,
      pingbimen: 0,
      diaodu: 0,
      chengwu: 0,
      zhanwu: 0,
      chengke: 0,
      otherEquipment: 0,
      other: 0,
    },
    delayEvent: { m5: 0, m15: 0, m30: 0 },
    reliability: { m5: 0, m15: 0, m30: 0 },
    speed: { up: 0, down: 0 },
    tripTime: { up: 0, down: 0 },
    totalTime: 0,
    cleanPassenger: 0,
    cleanPassengerFrequency: 0,
    rescueAmount: 0,
  };
}

// let TweenMax = Object;
// TweenMax.to = function(a,b,c){
//   return a+b+c;
// };

export default {
  name: "indexContent",
  components: {},

  data() {
    return {
      baseDataYear: "2018",
      /*option:{
          line:[
              {text:'2号线',value:2,color:"#ee7a33"},
              {text:'3号线',value:3,color:"#e40082"},
              {text:'4号线',value:4,color:"#00A14E"},
          ],
          year:[
              {text:'2018年基础运行数据',value:2018},
              {text:'2017年基础运行数据',value:2017},
          ]
      },*/
      choosed: {
        year: 2019,
        line: 'chengdu-2-'
      },
      animateShowData: initialDataFac(),
      totalData: [],
      curIcon: process.env.BASE_URL + "img/icon7.png",
    };
  },
  filters: {},
  computed: {
    showData: function () {
      let tmpLine = this.totalData.find(
        (v) =>
          v.line_code === this.choosed.line && v.year === this.choosed.year
      );
      return tmpLine;
    },
    option: function () {
      let tmp = { line: [], year: [] };
      for (let i of this.totalData) {
        if (tmp.line.findIndex((val) => val.text === i.line_name) === -1) {
          tmp.line.push({
            text: i.line_name,
            value: i.line_code,
            color: i.line_color,
          });
        }
        if (tmp.year.findIndex((val) => val.value === i.year) === -1) {
          tmp.year.push({ text: i.year + "年基础运行数据", value: i.year });
        }
      }
      return tmp;
    },
  },
  watch: {
    // showData:function(value){
    //   this.activeData(value);
    // },
  },
  mounted() {},
  created() {
    this.get_data();
  },
  methods: {
    get_data() {
      this.$axios
        .post(this.$url + "/ask_base_data", {
          userToken: userToken,
        })
        .then((response) => {
          if (response.data.stat === "ok") {
            response.data.res_record.sort((a,b)=>parseInt(a.line_code)-parseInt(b.line_code));
            this.totalData = response.data.res_record;
            this.activeData();
          } else {
            console.log("fali");
          }
        });
    },
    prevTo() {
      let index = this.option.line.findIndex(i=>i.value===this.choosed.line);
      let tl = this.option.line.length;
      if (index > 0) {
        this.choosed.line = this.option.line[index-1].value;
      } else {
        this.choosed.line = this.option.line[tl-1].value;
      }
      this.valueChange();
    },
    nextTo() {
      let index = this.option.line.findIndex(i=>i.value===this.choosed.line);
      let tl = this.option.line.length;
      if (index < tl-1) {
        this.choosed.line = this.option.line[index+1].value;
      } else {
        this.choosed.line = this.option.line[0].value;
      }
      this.valueChange();
    },
    link: function (value) {
      location.href = value;
    },
    chooseLine(value) {
      this.choosed.line = value.value;
      this.valueChange();
    },
    valueChange(){
      this.activeData();
    },
    activeData() {
      let value = this.totalData.find(
        (v) =>
           v.line_code === this.choosed.line && v.year === this.choosed.year
      );
      console.log(value);
      this.animateShowData = initialDataFac();
      // this.animateShowData = value;
      TweenMax.to(this.$data.animateShowData, 1, { runDays: value.runDays });
      TweenMax.to(this.$data.animateShowData, 1, { runMil: value.runMil });
      TweenMax.to(this.$data.animateShowData, 1, {
        actualAmount: value.actualAmount,
      });
      TweenMax.to(this.$data.animateShowData, 1, {
        planRatio: value.planRatio,
      });
      TweenMax.to(this.$data.animateShowData, 1, {
        ontimeRatio: Math.round(value.ontimeRatio*100)/100,
      });
      TweenMax.to(this.$data.animateShowData, 1, {
        yearPassengerFlow: value.yearPassengerFlow,
      });
      TweenMax.to(this.$data.animateShowData, 1, {
        maxHourFlow: value.maxHourFlow,
      });
      TweenMax.to(this.$data.animateShowData, 1, {
        dayAverageTransfer: Math.round(value.dayAverageTransfer*10000)/10000,
      });
      
      
    },
  },
};
</script>
<style scoped>
body,
ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
table,
td,
img,
div,
dl,
dt,
dd,
input {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: "Microsoft YaHei";
  font-size: 12px;
}

.outer-wrapper {
  overflow: hidden;
  background-color: #fff;
  margin: 0 auto;
  position: relative;
  padding-bottom: 20px;
  background: url("~@/assets/bg1.jpg") right no-repeat;
  background-size: auto;
}

.base-data-box {
  width: 1260px;
  margin: 0 auto;
  border-radius: 2px;
  padding: 10px 0;
}

.tab-bar {
  position: relative;
  margin: 0 109px 10px;
  padding: 0 64px;
  background: rgba(0, 0, 0, 0.5);
  /*width: fit-content;*/
}
.scroll {
  margin-top: 20px;
  position: relative;
  height: 104px;
  overflow: hidden;
}
ul {
  position: absolute !important;
  left: 0;
  top: 20px;
  white-space: nowrap;
  font-size: 0;
}
ol,
ul,
li {
  list-style: none;
}
.tab-term {
  display: inline-block;
  width: 58px;
  height: 64px;
  margin: 0 2px;
  vertical-align: top;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.19);
  cursor: pointer;
}
.cir {
  position: relative;
  width: 30px;
  height: 30px;
  margin: 4px auto 0;
}
.cir:before {
  border: 14px solid;
  opacity: 0.15;
  visibility: hidden;
}
.tab-term .cir:before,
.tab-term .cir:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 0;
  height: 0;
  margin: auto;
  border-radius: 50%;
  z-index: 0;
}
.ico {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 12px;
  height: 12px;
  margin: auto;
  border-radius: 50%;
  background-color: #fff;
  z-index: 1;
}
.cir:after {
  border: 9px solid;
}
.ico > img {
  display: block;
  width: 100%;
}
.tab-term .p {
  font-size: 9px;
  line-height: 1;
  color: #474747;
  text-align: center;
  margin-bottom: 7.5px;
}

.tab-term:hover > .p {
  color: #000;
  font-weight: 800;
}

.tab-term:hover > .arr {
  color: #000;
}
.tab-term:hover > .ico > img {
  display: block;
}
.tab-term:hover {
  transform: scale(1.1);
}

.tab-term .arr:before,
.tab-term .arr:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-left: transparent;
  border-right: transparent;
  border-style: solid;
  border-bottom: 0 none;
}
.tab-term .arr {
  position: relative;
  display: block;
  color: #8e8e8e;
}
.tab-term .arr:before {
  border-width: 3px;
}
.tab-term .arr:after {
  border-width: 2px;
  color: #fff;
}

.prev,
.next {
  position: absolute;
  top: 0;
  width: 128px;
  height: 100%;
  background: left no-repeat;
  cursor: pointer;
}
.prev {
  left: -40px;
  background-image: url("~@/assets/prev1.png");
}

.next {
  right: -40px;
  background-image: url("~@/assets/next1.png");
}
.prev:hover,
.next:hover {
  background-position: right;
}
.base-data {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
}
.base-data-element {
  width: 200px;
  height: 150px;
  padding: 40px 20px;
  margin: 20px;
  text-align: center;
  color: #000;
  font-weight: 800;
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 6px;
  float: left;
  cursor: pointer;
}
.ele-num {
  font-size: 22px;
}
.select-box {
  padding-top: 30px;
  opacity: 0.7;
  text-align: center;
}
select {
  color: #000;
  width: 270px !important;
  height: 40px;
  padding: 0px 20px;
  padding-left: 30px;
  font-size: 20px;
  font-weight: 800;
}
option {
  text-align: center;
}
.little-label {
  font-size: 14px;
  font-weight: 500;
}
.ele-title {
  margin-top: 10px;
}
.double {
  padding-top: 25px;
}
.ch-line {
  transform: scale(1.1);
}
</style>